import {action, computed, flow, makeObservable, observable} from "mobx";
import {
	CourseCategory,
	CourseTiles,
	GetCourseTilesResponse,
	ICourseClient,
	ILookupClient,
	IQualificationClient,
	LookupItemDto,
	QualificationDto,
} from "../common/webapicall";
import {LoadingState} from "../types/LoadingState";

export class SearchStore {
	constructor(private courseClient: ICourseClient, private lookupClient: ILookupClient, private qualificationClient: IQualificationClient) {
		makeObservable(this);
	}

	@observable public searchTerm: string;
	@observable courses: CourseTiles[] = [];
	@observable topics: LookupItemDto[] = [];
	@observable qualifications: QualificationDto[] = [];

	@observable loadingState: LoadingState;
	@observable categoryFilters: Set<CourseCategory> = new Set();
	@observable topicFilters: Set<string> = new Set();
	@observable qualificationFilters: Set<string> = new Set();

	@action
	public categoryFilterChange(value: CourseCategory) {
		if (this.categoryFilters.has(value)) {
			this.categoryFilters.delete(value);
		} else {
			this.categoryFilters.add(value);
		}
	}

	@action
	public topicFilterChange(value: string) {
		const searchValue = value.toLowerCase();
		if (this.topicFilters.has(searchValue)) {
			this.topicFilters.delete(searchValue);
		} else {
			this.topicFilters.add(searchValue);
		}
	}

	@action
	public qualificationFilterChange(value: string) {
		const searchValue = value.toLowerCase();
		if (this.qualificationFilters.has(searchValue)) {
			this.qualificationFilters.delete(searchValue);
		} else {
			this.qualificationFilters.add(searchValue);
		}
	}

	@action
	public clearFilters() {
		this.topicFilters.clear();
		this.categoryFilters.clear();
		this.qualificationFilters.clear();
	}

	@action
	public setSearchTerm(value: string) {
		this.searchTerm = value;
	}

	@computed
	public get filteredCourses() {
		if (this.courses.length === 0) {
			return [];
		}

		const keywords = this.searchTerm.split(" ");

		const courses = this.courses.filter(
			c =>
				keywords.some(
					keyword =>
						c.title.toLocaleLowerCase().includes(keyword) ||
						c.description.toLocaleLowerCase().includes(keyword) ||
						c.topics.some(t => t.name.toLocaleLowerCase().includes(keyword)),
				) &&
				(this.categoryFilters.size === 0 || this.categoryFilters.has(c.category)) &&
				(this.topicFilters.size === 0 || c.topics.some(t => this.topicFilters.has(t.id))) &&
				(this.qualificationFilters.size === 0 || c.qualifications.some(t => this.qualificationFilters.has(t.id))),
		);

		return courses;
	}

	@computed
	public get hasFilter() {
		return this.categoryFilters.size > 0 || this.topicFilters.size > 0 || this.qualificationFilters.size > 0;
	}

	@flow
	public *load() {
		this.loadingState = "loading";

		const tiles: GetCourseTilesResponse = yield this.courseClient.getCourseTiles(null, null, null, 1000);
		const topics: LookupItemDto[] = yield this.lookupClient.getAllTopics();
		const qualies: QualificationDto[] = yield this.qualificationClient.getAllQualifications();

		this.courses = tiles?.courseTiles ?? [];
		this.topics = topics.sort((a, b) => (a.name > b.name ? 1 : -1));
		this.qualifications = qualies;

		this.loadingState = "completed";
	}

	@action
	public unLoad() {
		this.searchTerm = "";
		this.topicFilters.clear();
		this.qualificationFilters.clear();
		this.categoryFilters.clear();

		this.courses = [];
		this.topics = [];
		this.qualifications = [];
	}
}
