import {action, flow, makeObservable, observable} from "mobx";
import {IQualificationClient, QualificationDetailDto, QualificationDto} from "../common/webapicall";
import {LoadingState} from "../types/LoadingState";

export class QualificationStore {
	@observable public qualifications: QualificationDto[] = [];
	@observable public loadingState: LoadingState = "initial";

	@observable public detailLoadingState: LoadingState = "initial";
	@observable public qualificationDetail: QualificationDetailDto;

	constructor(private qualificationClient: IQualificationClient) {
		makeObservable(this);
	}

	@flow
	public *load() {
		this.loadingState = "loading";

		const qualies: QualificationDto[] = yield this.qualificationClient.getAllQualifications();
		this.qualifications = qualies;

		this.loadingState = "completed";
	}

	@flow
	public *loadDetail(id: string) {
		this.detailLoadingState = "loading";

		const qualies: QualificationDetailDto = yield this.qualificationClient.getQualificationDetails(id);
		this.qualificationDetail = qualies;

		this.detailLoadingState = "completed";
	}

	@action
	public unloadDetail() {
		this.detailLoadingState = "initial";
		this.qualificationDetail = null;
	}
}
